<template>
  <div data-app class="d-flex flex-column" style="background-color: #F9F9F9;">
    <div :class="`d-flex flex-column ${isWide ? 'w-50 mx-auto my-25' : isMobile ? 'm-2' : 'w-75 mx-auto my-15'}`">
      <span :class="`font-bold ${isWide ? 'font-30' : isMobile ? 'm-5 font-20' : 'font-25'}`">{{ $t(`${$route.params.employeeId ? 'edit_employee' : 'add_employee'}`) }}</span>
      <div :class="`${isMobile ? 'mt-2' : 'mt-5'} d-flex justify-content-start vertical-center pointer`" @click="goBackToEditDrink()" v-if="editDrinkEventId">
        <i :class="`${isMobile ? 'font-12' : 'font-20'} mr-2 color-icon fa fa-angle-left`" />
        <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('Getränkekarte bearbeiten') }}</span>
      </div>
      <div :class="`${isMobile ? 'mt-5' : 'mt-10 px-15 py-10'} box-white d-flex flex-column`">
        <span :class="`ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('employee_name') }}</span>
        <div class="w-100 mt-2 input-with-icon vertical-center">
          <img :src="assets.employee" :width="16" :height="16" />
          <input class="ml-2" v-model="employeeName" />
        </div>
        <span :class="`mt-5 ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('link_event') }}</span>
        <div class="w-100 mt-2 input-with-icon d-flex vertical-center pointer" @click="visibleSelectEventDialog = true">
          <img :src="assets.event" :width="16" :height="16" />
          <span class="ml-2">{{ getEventName() }}</span>
          <i :class="`ml-auto ${isMobile ? 'font-12' : 'font-15'} fa fa-angle-down`" />
        </div>
        <span :class="`mt-5 ml-3 font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('role') }}</span>
        <div class="mt-2 d-flex justify-content-start vertical-center">
          <span :class="`${isMobile ? 'font-12' : 'font-15'} ${role === EMPLOYEE_ROLE_TICKET ? 'box-selected' : 'box-normal'}`" @click="role = EMPLOYEE_ROLE_TICKET">{{ $t('ticket') }}</span>
          <span :class="`ml-5 ${isMobile ? 'font-12' : 'font-15'} ${role === EMPLOYEE_ROLE_DRINK ? 'box-selected' : 'box-normal'}`" @click="role = EMPLOYEE_ROLE_DRINK">{{ $t('drink') }}</span>
        </div>
        <div class="mt-5 ml-3 d-flex justify-content-between vertical-center pointer" @click="inUse = !inUse">
          <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('in_use') }}</span>
          <img :src="inUse ? assets.switch_on : assets.switch_off" :height="isWide ? 25 : 15" />
        </div>
        <div class="mt-5 ml-3 d-flex justify-content-between vertical-center pointer" @click="handleDrinkRequest = !handleDrinkRequest" v-if="role === EMPLOYEE_ROLE_DRINK">
          <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('handle_drink_request') }}</span>
          <img :src="handleDrinkRequest ? assets.switch_on : assets.switch_off" :height="isWide ? 25 : 15" />
        </div>
        <div class="mt-5 ml-3 d-flex justify-content-between vertical-center pointer" @click="useManualTip = !useManualTip" v-if="role === EMPLOYEE_ROLE_DRINK">
          <span :class="`font-bold ${isMobile ? 'font-12' : 'font-15'}`">{{ $t('use_manual_tip') }}</span>
          <img :src="useManualTip ? assets.switch_on : assets.switch_off" :height="isWide ? 25 : 15" />
        </div>
      </div>
      <div class="my-10 d-flex justify-content-center">
        <v-btn class="button-normal" @click="setEmployeeInfoTask()">
          <span :class="`${isMobile ? 'mx-20' : 'mx-40'}`">{{ $t(`${$route.params.employeeId ? 'save' : 'add'}`) }}</span>
        </v-btn>
      </div>
    </div>
    <!-- Start Select Event Dialog -->
    <v-dialog v-model="visibleSelectEventDialog" :width="isWide ? '600px' : ''">
      <v-card>
        <v-card-title class="bg-pink d-flex justify-content-between">
          <span :class="`font-bold color-white ${isMobile ? 'font-15' : 'font-18'}`">{{ $t('select_event') }}</span>
          <v-btn class="m-1" icon small @click="visibleSelectEventDialog = false">
            <i class="flaticon-cancel font-bold font-25 color-white"></i>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="m-5 d-flex flex-column" v-if="myEventList && myEventList.length > 0">
            <div class="my-2 box-white px-3 py-2 pointer" v-for="(item, index) in myEventList" :key="index"  @click="eventId = item.eventId; visibleSelectEventDialog = false;">
              <div class="mt-2 vertical-center">
                <span class="font-12 color-blue">{{ getDateStringFromTimestamp(item.startAt) }} - {{ getDateStringFromTimestamp(item.endAt) }}</span>
              </div>
              <span class="ellipsis font-bold font-18" :title="item.name"> {{ item.name }}</span>
            </div>
          </div>
          <div class="text-center" v-else>
            {{ $t('no_data_found') }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- End Select Event Dialog -->
  </div>
</template>


<script>
import { httpsCallable } from 'firebase/functions';
import { functions, firestore } from '../../../main';
import { onSnapshot, doc } from 'firebase/firestore';
import { getDateStringFromTimestamp, showLoading, showFunctionError } from '../../../functions';

import employee from '@/assets/images/employee.png';
import event from '@/assets/images/event.png';
import switch_on from '@/assets/images/switch_on.png';
import switch_off from '@/assets/images/switch_off.png';

export default {
  name: 'EditEmployee',
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    isWide() {
      return this.$store.state.isWide;
    },
    myInfo() {
      return this.$store.state.myInfo;
    },
    myEventList() {
      return this.$store.state.myEventList;
    }
  },
  data() {
    return {
      editDrinkEventId: null,
      visibleSelectEventDialog: false,
      assets: {
        employee,
        event,
        switch_on,
        switch_off
      },
      employeeName: '',
      eventId: '',
      role: 0,
      inUse: true,
      handleDrinkRequest: false,
      useManualTip: false,
      snapEmployeeInfo: null
    };
  },
  mounted() {
    if (this.checkPermission()) {
      this.editDrinkEventId = localStorage.getItem('eventboxEditDrinkEventId');
      this.getEmployeeInfoTask();
    }
  },
  destroyed() {
    if (this.snapEmployeeInfo) {
      this.snapEmployeeInfo();
    }
  },
  methods: {
    getDateStringFromTimestamp,
    checkPermission() {
      if (this.myInfo) {
        if (this.myInfo.userType === this.USER_ORGANIZER) {
          return true;
        } else {
          this.$toast.error('No permission!!');
          this.$router.push('/');
          return false;
        }
      } else {
        this.$toast.info(this.$t('alert_sign_in'));
        this.$router.push('/');
        return false;
      }
    },
    goBackToEditDrink() {
      if (this.editDrinkEventId) {
        localStorage.setItem('eventboxEditDrinkEventId', '');
        this.$router.push(`/edit_drink/${this.editDrinkEventId}`);
      }
    },
    getEventName() {
      if (this.myEventList && this.eventId) {
        const eventInfo = this.myEventList.find(element => element.eventId === this.eventId);
        return eventInfo ? eventInfo.name : this.$t('select_event');
      } else {
        return this.$t('select_event');
      }
    },
    getEmployeeInfoTask() {
      if (this.snapEmployeeInfo || !this.$route.params.employeeId) {
        return;
      }
      const loader = showLoading(this);
      this.snapEmployeeInfo = onSnapshot(doc(firestore, 'employee', this.$route.params.employeeId), doc => {
        loader.hide();
        const employeeInfo = doc.data();
        if (employeeInfo) {
          this.employeeName = employeeInfo.name;
          this.eventId = employeeInfo.eventId;
          this.role = employeeInfo.role;
          this.inUse = employeeInfo.inUse;
          this.handleDrinkRequest = employeeInfo.handleDrinkRequest || false;
          this.useManualTip = employeeInfo.useManualTip || false;
        }
      });
    },
    setEmployeeInfoTask() {
      if (!this.checkPermission()) {
        return;
      }
      if (!this.employeeName) {
        this.$toast.error(this.$t('alert_required_field', [this.$t('employee_name')]));
        return;
      }
      if (!this.eventId) {
        this.$toast.error(this.$t('alert_select_event'));
        return;
      }
      if (this.role === 0) {
        this.$toast.error(this.$t('alert_select_role'));
        return;
      }
      if (this.role != this.EMPLOYEE_ROLE_DRINK) {
        this.handleDrinkRequest = false;
        this.useManualTip = false;
      }
      const params = {
        userId: this.myInfo.userId,
        name: this.employeeName,
        eventId: this.eventId,
        role: this.role,
        inUse: this.inUse,
        handleDrinkRequest: this.handleDrinkRequest,
        useManualTip: this.useManualTip
      }
      if (this.$route.params.employeeId) {
        params['employeeId'] = this.$route.params.employeeId;
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'setEmployeeInfo');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data === this.RESULT_SUCCESS) {
          if (this.$route.params.employeeId) {
            this.$toast.success(this.$t('alert_updated_successfully'));
          } else {
            this.$toast.success(this.$t('alert_added_successfully'));
          }
          this.$router.push(`/employees`);
        } else {
          showFunctionError(this, response.data);
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>